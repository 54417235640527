/* post-job.css */

.post-job-container {
  padding: 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
}

/* Notification Messages */
.notification {
  position: fixed;
  top: 2rem;
  right: 2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 500;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.3s ease;
}

.notification.visible {
  transform: translateY(0);
  opacity: 1;
}

.notification.success {
  background-color: #10b981;
}

.notification.error {
  background-color: #ef4444;
}

/* Card styling */
.post-job-card {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-bottom: 2rem;
}

.post-job-header {
  margin-bottom: 2rem;
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1.5rem;
}

.post-job-heading {
  font-size: 1.75rem;
  font-weight: 700;
  color: #111827;
  margin-bottom: 0.5rem;
}

.post-job-subheading {
  color: #6b7280;
  font-size: 1rem;
}

/* Form sections */
.form-section {
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1rem;
}

.section-description {
  color: #6b7280;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

/* Form layout */
.form-row {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
}

.form-group {
  margin-bottom: 1.5rem;
  flex: 1;
}

/* Form elements */
.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #374151;
}

.required {
  color: #ef4444;
}

.job-input, .job-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #1f2937;
  transition: border-color 0.15s ease-in-out;
}

.job-input:focus, .job-select:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
}

.job-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%236b7280' d='M4.5 6l3.5 3.5L11.5 6H4.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 16px;
  padding-right: 2.5rem;
}

.error {
  border-color: #ef4444;
}

.error-text {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.375rem;
  display: block;
}

/* Quill editor styling */
.editor-container {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #d1d5db;
}

.editor-container.error {
  border-color: #ef4444;
}

.job-description-editor {
  background-color: white;
  height: 250px;
}

.quill .ql-toolbar {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5e7eb;
  padding: 0.75rem;
}

.quill .ql-container {
  border: none;
  height: calc(250px - 43px);
}

/* Buttons */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.primary-button, .secondary-button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease;
  cursor: pointer;
}

.primary-button {
  background-color: #3b82f6;
  color: white;
  border: 1px solid #3b82f6;
}

.primary-button:hover {
  background-color: #2563eb;
  border-color: #2563eb;
}

.secondary-button {
  background-color: white;
  color: #4b5563;
  border: 1px solid #d1d5db;
}

.secondary-button:hover {
  background-color: #f3f4f6;
  border-color: #9ca3af;
}

.primary-button:disabled, .secondary-button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

/* Loading spinner */
.spinner {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}