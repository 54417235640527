/* Login Page Styling */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f0f4ff, #e6e9ff); /* Soft pastel gradient */
    padding: 24px;
}

.login-card {
    background: rgba(255, 255, 255, 0.95); /* Semi-transparent white */
    backdrop-filter: blur(12px);
    padding: 32px;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 380px;
    color: #333; /* Darker text for contrast */
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

.login-title {
    font-size: 24px;
    font-weight: 600; /* Semi-bold for a professional look */
    margin-bottom: 20px;
    color: #4a5568; /* Soft gray for the title */
}

.input-group {
    text-align: left;
    margin-bottom: 16px;
}

.input-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #4a5568; /* Soft gray for labels */
}

.input-field {
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.9); /* Light background */
    border: 1px solid rgba(203, 213, 224, 0.5); /* Soft border */
    border-radius: 8px;
    color: #333; /* Dark text for readability */
    outline: none;
    font-size: 16px;
    transition: all 0.3s;
}

.input-field:focus {
    background: rgba(255, 255, 255, 1); /* Brighter on focus */
    border-color: #a0aec0; /* Subtle border color */
}

.login-btn {
    width: 100%;
    background: linear-gradient(90deg, #6b46c1, #805ad5); /* Pastel purple gradient */
    padding: 12px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600; /* Semi-bold for buttons */
    color: white;
    cursor: pointer;
    margin-top: 16px;
    border: none;
    transition: all 0.3s ease;
}

.login-btn:hover {
    background: linear-gradient(90deg, #805ad5, #6b46c1); /* Slightly darker gradient on hover */
    transform: scale(1.02);
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}