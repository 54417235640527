/* Global Styling */
body {
  font-family: 'Roboto', sans-serif;
  background: #f4f7fa;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Container Styling */
.manage-applications {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Header & Action Buttons */
.applications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #007bff;
  color: #fff;
}

.applications-heading {
  font-size: 24px;
  margin: 0;
}

.applications-actions button {
  background: #fff;
  color: #007bff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.applications-actions button:hover {
  background: #e6f0ff;
}

/* Search & Filter Container */
.search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #f9fafb;
  border-bottom: 1px solid #eee;
}

.search-box {
  position: relative;
  flex: 1;
}

.search-box input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s ease;
}

.search-box input:focus {
  border-color: #007bff;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

/* Filter Button */
.filter-button {
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.filter-button:hover {
  opacity: 0.9;
}

/* Modal (Filter Popup) Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.filter-modal {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.close-icon {
  cursor: pointer;
  font-size: 24px;
}

.modal-body .filter-group {
  margin-bottom: 15px;
}

.modal-body .filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-body .filter-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.date-inputs {
  display: flex;
  align-items: center;
}

.date-inputs input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.date-inputs span {
  margin: 0 10px;
  font-weight: bold;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.apply-button,
.reset-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.apply-button {
  background: #007bff;
  color: #fff;
}

.apply-button:hover {
  background: #0056b3;
}

.reset-button {
  background: #f44336;
  color: #fff;
}

.reset-button:hover {
  background: #d32f2f;
}

/* Table Styles */
.table-container {
  padding: 20px;
  overflow: auto;
}

.applications-table {
  width: 100%;
  border-collapse: collapse;
}

.applications-table th,
.applications-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.applications-table th {
  background: #007bff;
  color: #fff;
}

.applications-table tr:nth-child(even) {
  background: #f9f9f9;
}

/* Pagination Styles */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination-button {
  margin: 0 5px;
  padding: 8px 14px;
  font-size: 13px;
  /* background-color: #007bff; */
  color: #0c0b0b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #0056b3;
}

/* Other Elements */
.applications-stats {
  padding: 15px 20px;
  display: flex;
  gap: 30px;
  background: #f9fafb;
  border-top: 1px solid #eee;
}

.stat-item {
  font-size: 16px;
}

.stat-label {
  font-weight: bold;
  margin-right: 5px;
}

.no-applications {
  text-align: center;
  padding: 40px 20px;
}

.empty-state-icon {
  font-size: 48px;
  margin-bottom: 15px;
}

/* Delete Confirmation Modal */
.delete-confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1100;
}

.delete-confirmation-content {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  width: 350px;
  text-align: center;
}

.delete-confirmation-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.cancel-button,
.confirm-delete-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background: #ccc;
  color: #333;
}

.confirm-delete-button {
  background: #f44336;
  color: #fff;
}




