/* 5.01 - About Page - Start
================================================== */
.about_image_1 {
	position: relative;
	margin-left: -273px;
	img {
		&:nth-child(2) {
			top: 170px;
			left: 200px;
			max-width: 75px;
			position: absolute;
		}
		&:nth-child(3) {
			right: 40px;
			bottom: 105px;
			max-width: 80px;
			position: absolute;
		}
	}
}

.about_and_case_section {
	padding: 180px 0 81px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	.shape_image_1 {
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		img {
			width: calc(100% - 530px);
			transform: translateX(5px);
		}
	}
}
.about_image_2 {
	gap: 20px;
	display: flex;
	position: relative;
	align-items: flex-start;
	margin-left: 50px;
	.image_wrap {
		flex: 0 0 auto;
		max-width: 198px;
		overflow: hidden;
		border-radius: var(--bs-border-radius);
	}
	.space_line {
		top: 142px;
		left: 218px;
		max-width: 368px;
		position: absolute;
	}
}

.about_funfact_info {
	z-index: 1;
	// width: 472px;
	width: 80%; /* Ensures it takes up the full width on small screens */
	flex: 0 0 auto;
	padding: 20px 30px;
	position: relative;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	margin: 0 auto; /* Added to center the container on smaller screens */
	.customer_count {
		>ul {
			margin-bottom: 12px;
			display: flex; /* Added to create a flex container for smaller screens */
			flex-wrap: wrap; /* Ensures that items wrap to the next line on smaller screens */
			justify-content: center; /* Center align the items */
		  
			>li {
				width: 50px;
				height: 50px;
				font-size: 15px;
				overflow: hidden;
				line-height: 28px;
				border-radius: 100%;
				align-items: center;
				display: inline-flex;
				color: var(--bs-white);
				justify-content: center;
				border: 2px solid var(--bs-white);
				background-color: var(--bs-primary);
				&:not(:first-child) {
					margin-left: -15px;
				}
			}
		}
	}
	.about_funfact_counter {
		gap: 50px;
		display: flex;
		margin-top: 46px;
		justify-content: flex-end;
		flex-wrap: wrap; /* Added to ensure it wraps on smaller screens */
        justify-content: center; /* Center align items */
		.counter_value {
			line-height: 1;
			font-size: 45px;
			font-weight: 700;
			display: inline-flex;
			color: var(--bs-dark);
			font-family: 'Axiforma Bold';
		}
		.funfact_title {
			color: #676767;
			font-size: 16px;
			font-weight: 400;
			font-family: 'Axiforma Regular';
		}
	}
	.btn {
		top: 0;
		right: -5px;
		position: absolute;
		border-radius: var(--bs-border-radius);
		padding: 0 20px;
	}
	.icon_globe {
		left: 22px;
		bottom: 22px;
		max-width: 40px;
		position: absolute;
		&:before,
		&:after {
			top: 0;
			left: 0;
			width: 100%;
			content: '';
			height: 100%;
			position: absolute;
			border-radius: 100%;
			transition: all 0.33s ease;
			animation: ripple 1.5s linear infinite;
			border: 1px solid var(--bs-white);
		}
		&:after {
			animation-delay: 0.9s;
		}
	}
}

.about_image_3 {
	position: relative;
	.image_wrap {
		overflow: hidden;
		border-radius: var(--bs-border-radius);
	}
	.funfact_block {
		left: -154px;
		bottom: 85px;
		position: absolute;
		.funfact_icon {
			background-color: #FFBE16;
		}
	}
}

// About Page 
body:has(.page_banner_section + .intro_about_section) .page_banner_section {
	padding-bottom: 394px;
}
.intro_about_section {
	.intro_about_content {
		z-index: 1;
		position: relative;
		margin: -420px 0 60px;
		.image_wrap {
			overflow: hidden;
			border-radius: var(--bs-border-radius-sm);
		}
	}
}

.about-page {
	.page_banner_section {
		background-image: none!important;
	}
	.client_logo_section {
		background-image: none!important;
	}
}

.service-single-page {
	overflow: hidden;
	.process_technology_review_section {
		padding-top: 80px;

		@media(max-width:991px){
			padding-top: 30px;
		}

		.decoration_item {
	       display: none;
		}
	}
}

.site_footer {
	overflow: hidden;
}
/* 5.01 - About Page - End
================================================== */

